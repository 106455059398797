import { createTheme } from "@mui/material/styles";

export default createTheme({
  palette: {
    primary: {
      main: "#71479b",
    },
    secondary: {
      main: "#6ec4b1",
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          marginTop: "0px !important",
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          "& .MuiGrid-item": {
            padding: 8,
          },
        },
      },
    },
  },
});
