import * as yup from "yup";

const buildFileValidation = (filename) => {
  return yup.mixed()
    .test('fileRequired', `You have to upload ${filename}`, (value) => value[0])
    .test('fileFormat', `Wrong file format to ${filename}`, (value) => {
      const file = value[0]
      let validFile = false
      if (file) {
        const availableFormats = ["application/pdf", "image/jpg", "image/png", "image/jpeg", "image/gif"]
        validFile = availableFormats.includes(file.type)
      }
      return file && validFile
    })
    .test('fileSize', `Wrong file size to ${filename}`, (value) => {
      const file = value[0]
      let validFile = false
      if (file) {
        const fileSize = 1 * 1000000
        validFile = file.size < fileSize
      }
      return file && validFile
    })
}

// const buildUrlValidation = (filename) => {
//   return yup.string().url()
//     .test('urlRequired', `Url for ${filename} is required`, (value) => value)
//     .test('urlRemove', `Remove Protocol(http/https) and www. from your URL - ${filename}`, (value) => {
//       const regMatch = /^(www\.).*$/;
//       console.log('regMatch.test(value)', regMatch.test(value))
//       return !regMatch.test(value)
//     })
//     .test('httpsValidation', `The URL of ${filename} have to be secure (https)`, (value) => {
//       const countProtocols = value.split('https://')
//       return countProtocols.length < 3
//     })
// }


export default yup.object().shape({
  director: yup.object().shape({
    name: yup.string().required(`Director's Name is required`),
    postal_address: yup.string().required(`Director's Postal Address is required`),
    phone: yup.string().required(`Director's Phone is required`),
    email: yup.string().email(),
    wire_information: yup.string(),
  }),
  driver_licence: buildFileValidation(`Director's Driver Licence`).required(`Director's Driver Licence is required`),
  corporate_resolution: buildFileValidation('Corporate Resolution').required('Corporate Resolution is required'),
  applicant_name: yup.string().required('Applicant Name is required'),
  image_f: buildFileValidation('Approved Logo').required('Approved Logo is required'),
  approved_logo_url: yup.string().url(),
  charity_twitter: yup.string().url(),
  charity_instagram: yup.string().url(),
  charity_facebook: yup.string().url(),
  charity_promotional_video: yup.string().url(),
  web_url: yup.string().url(),
  charity_email: yup.string().email(),
  phone: yup.string().required('Organization Phone is required'),
  tax_id: buildFileValidation('Tax ID').required('Tax ID is required'),
  zipcode: yup.string(),
  address: yup.string().required('Physical Address is required'),
  reg_number: yup.string(),
  slogan: yup.string(),
  mission_description: yup.string().required('Mission Statement is required'),
  category: yup.string().required('Charity Type is required'),
  founder_name: yup.string().required('Founder Name is required'),
  name: yup.string().required('Application name is required'),
  invitation_code: yup.string().required('Invitation Code is required'),
});
