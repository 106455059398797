import API_REQUEST from "./instance";

export const AddNewOrganization = (data) => {
  return API_REQUEST({
    url: "/api/v0/organizations/public",
    method: "post",
    data,
  });
};

export const GetCharityTypes = (params) => {
  return API_REQUEST({
    url: "/api/v0/organizations/charities",
    method: "GET",
    params,
  });
};
