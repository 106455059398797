import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import { LoadingButton } from "@mui/lab";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import theme from "./mui_theme";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from 'sweetalert2'

// CONSTANS
import maindefault from "./constant/forms/main.variables";

// VALIDATIONS
import CharitySchema from "./validations/charity.validation";

// SERVICES
import {
  AddNewOrganization,
  GetCharityTypes,
} from "./services/charity.service";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://givbux.com/">
        GIVBUX - IT PAYS TO GIVE{" "}
      </Link>
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const RequiredLabel = ({ title }) => {
  return (
    <label>
      {title} <b style={{ color: "red" }}>*</b>
    </label>
  );
};

export default function CharityForms() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: maindefault,
    shouldFocusError: true,
    resolver: yupResolver(CharitySchema),
    criteriaMode: "firstError",
  });

  const allErrors = Object.values(errors);
  const currenError = allErrors[allErrors.length - 1];
  console.log("currenError", currenError);
  const [charities, setCharities] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState(false);

  React.useEffect(() => {
    getCharities();
  }, []);

  React.useEffect(() => {
    if (currenError) {
      if (currenError.message) {
        setMessage({ text: currenError.message, type: "warning" });
      } else {
        const deepError = Object.entries(currenError)
        console.log('deepError', deepError)
        setMessage({ text: deepError[0][1].message, type: "warning" });
      }
    }
  }, [errors]);

  const getCharities = async () => {
    try {
      const { data } = await GetCharityTypes();
      setCharities(data.results);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleNewOrganization = async (data) => {
    console.log("data", data);
    setLoading(true);
    try {
      // await CharitySchema.validate(toValidate);
      const tosend = new FormData();
      let keys = Object.keys(data);
      keys.map((key) => {
        let value =
          typeof data[key] == "object" ? JSON.stringify(data[key]) : data[key];
        tosend.append(key, value);
        return true;
      });
      tosend.set("driver_licence", data.driver_licence[0]);
      tosend.set("corporate_resolution", data.corporate_resolution[0]);
      tosend.set("image_f", data.image_f[0]);
      tosend.set("tax_id", data.tax_id[0]);
      await AddNewOrganization(tosend);
      setLoading(false);
      reset(maindefault);
      Swal.fire(
        'Good job!',
        'Organization Charity - Created',
        'success'
      )
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.data) {
        setMessage({ text: error.response.data.message, type: "warning" });
      } else {
        setMessage({ text: error.message, type: "warning" });
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Snackbar
        open={Boolean(message)}
        autoHideDuration={6000}
        onClose={() => {
          setMessage(false);
        }}
      >
        <Alert severity={message?.type}>{message?.text}</Alert>
      </Snackbar>

      <AppBar
        position="absolute"
        elevation={0}
        sx={{
          position: "relative",
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            GivBux Charities
          </Typography>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="md" sx={{ mb: 8 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Box sx={{ my: 4, width: 360 }}>
              <Typography
                component="h1"
                variant="h4"
                className="mb-2"
                align="center"
              >
                How To Sign Up A Charity Or Organization
              </Typography>
            </Box>
          </Box>
          <React.Fragment>
            <form onSubmit={handleSubmit(handleNewOrganization)}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={
                      <RequiredLabel title="Sponsor / Enroll Invitation code" />
                    }
                    error={"invitation_code" in errors}
                    name="invitation_code"
                    {...register("invitation_code")}
                    variant="outlined"
                    helperText="Please enter the invitation code of the person that referred you to the GivBux Charity Program"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={<RequiredLabel title="Name of Charity" />}
                    error={"name" in errors}
                    name="name"
                    {...register("name")}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={<RequiredLabel title="Name of Founder" />}
                    error={"founder_name" in errors}
                    name="founder_name"
                    {...register("founder_name")}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="category">
                      <RequiredLabel title="Type of Charity" />
                    </InputLabel>
                    <Select
                      labelId="category"
                      id="category"
                      error={"category" in errors}
                      name="category"
                      {...register("category")}
                      label={<RequiredLabel title="Type of Charity" />}
                      variant="outlined"
                    >
                      <MenuItem value="">Select Charity Type</MenuItem>
                      {charities.map((charity, ind) => (
                        <MenuItem
                          key={`charity-${ind}`}
                          value={charity.objectId}
                        >
                          {charity.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={<RequiredLabel title="Mission Statement" />}
                    error={"mission_description" in errors}
                    name="mission_description"
                    {...register("mission_description")}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Charity Tagline / Slogan"
                    error={"slogan" in errors}
                    name="slogan"
                    {...register("slogan")}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Charity Registration Number"
                    error={"reg_number" in errors}
                    name="reg_number"
                    {...register("reg_number")}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={<RequiredLabel title="Physical Address" />}
                    error={"address" in errors}
                    name="address"
                    {...register("address")}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl sx={{ my: 2 }}>
                    <FormLabel htmlFor="tax_id">
                      <Typography>
                        <RequiredLabel title="Tax ID/EIN" />
                      </Typography>
                      <Typography variant="caption">
                        (No larger than 6MB, PDF Format. If the document cannot
                        be uploaded, then send via email to charity@givbux.com)
                      </Typography>
                    </FormLabel>
                    <Input
                      sx={{ p: 0 }}
                      id="tax_id"
                      error={"tax_id" in errors}
                      name="tax_id"
                      {...register("tax_id")}
                      type="file"
                    />
                    <FormHelperText>
                      Accepted file types: pdf, jpg, png, jpeg, gif, Max. file
                      size: 10 MB.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Zip Code"
                    error={"zipcode" in errors}
                    name="zipcode"
                    {...register("zipcode")}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={<RequiredLabel title="Phone Number" />}
                    error={"phone" in errors}
                    name="phone"
                    {...register("phone")}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Charity's Email"
                    error={"charity_email" in errors}
                    name="charity_email"
                    {...register("charity_email")}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Charity URL"
                    error={"web_url" in errors}
                    name="web_url"
                    {...register("web_url")}
                    variant="outlined"
                    helperText="eg: https://example.givbux.com"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Your Charity's Promotional Video URL"
                    error={"charity_promotional_video" in errors}
                    name="charity_promotional_video"
                    {...register("charity_promotional_video")}
                    variant="outlined"
                    helperText="eg: https://example.givbux.com"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Your Charity's Facebook"
                    error={"charity_facebook" in errors}
                    name="charity_facebook"
                    {...register("charity_facebook")}
                    variant="outlined"
                    helperText="eg: https://example.givbux.com"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Your Charity's Instagram"
                    error={"charity_instagram" in errors}
                    name="charity_instagram"
                    {...register("charity_instagram")}
                    variant="outlined"
                    helperText="eg: https://example.givbux.com"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Your Charity's Twitter"
                    error={"charity_twitter" in errors}
                    name="charity_twitter"
                    {...register("charity_twitter")}
                    variant="outlined"
                    helperText="eg: https://example.givbux.com"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl sx={{ my: 2 }}>
                    <FormLabel htmlFor="image_f">
                      <Typography>
                        <RequiredLabel title="Your Charity's Approved Logo" />
                      </Typography>
                      <Typography variant="caption">
                        (high resolution) (No larger than 6MB JPEG, PNG or PDF
                        Format. If the document cannot be uploaded, then send
                        via email to charity@givbux.com)
                      </Typography>
                    </FormLabel>
                    <Input
                      sx={{ p: 0 }}
                      id="image_f"
                      error={"image_f" in errors}
                      name="image_f"
                      {...register("image_f")}
                      type="file"
                    />
                    <FormHelperText>
                      Accepted file types: pdf, jpg, png, jpeg, gif, Max. file
                      size: 10 MB.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Your Charity's Approved Logo URL"
                    error={"approved_logo_url" in errors}
                    name="approved_logo_url"
                    {...register("approved_logo_url")}
                    variant="outlined"
                    helperText="eg: https://example.givbux.com"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={<RequiredLabel title="Applicant's Name" />}
                    error={"applicant_name" in errors}
                    name="applicant_name"
                    {...register("applicant_name")}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl sx={{ my: 2 }}>
                    <FormLabel htmlFor="corporate_resolution">
                      <Typography>
                        <RequiredLabel title="Signed Corporate Resolution from an Authorized Officer or Director of your Organization / Article of Incorporation" />
                      </Typography>
                      <Typography variant="caption">
                        (No larger than 6MB, PDF Format. If the document cannot
                        be uploaded, then send via email to charity@givbux.com)
                      </Typography>
                    </FormLabel>
                    <Input
                      sx={{ p: 0 }}
                      id="corporate_resolution"
                      error={"corporate_resolution" in errors}
                      name="corporate_resolution"
                      {...register("corporate_resolution")}
                      type="file"
                    />
                    <FormHelperText>
                      Accepted file types: pdf, jpg, png, jpeg, gif, Max. file
                      size: 10 MB.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                  <Typography
                    sx={{ my: 2 }}
                    component="h1"
                    variant="h5"
                    align="center"
                  >
                    Director's Contact Information
                  </Typography>

                  <Divider />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={<RequiredLabel title="Name of Director" />}
                    error={"name" in (errors.director ? errors.director : {})}
                    name="director.name"
                    {...register("director.name")}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={
                      <RequiredLabel title="Postal Address if different from Physical Address" />
                    }
                    error={
                      "postal_address" in
                      (errors.director ? errors.director : {})
                    }
                    name="director.postal_address"
                    {...register("director.postal_address")}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={<RequiredLabel title="Phone Number" />}
                    error={"phone" in (errors.director ? errors.director : {})}
                    name="director.phone"
                    {...register("director.phone")}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Email Address"
                    error={"email" in (errors.director ? errors.director : {})}
                    name="director.email"
                    {...register("director.email")}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl sx={{ my: 2 }}>
                    <FormLabel htmlFor="driver_licence">
                      <Typography>
                        <RequiredLabel title="Copy Of Director's Driver's License/Passport" />
                      </Typography>
                      <Typography variant="caption">
                        (No larger than 6MB, PDF Format. If the document cannot
                        be uploaded, then send via email to charity@givbux.com)
                      </Typography>
                    </FormLabel>
                    <Input
                      sx={{ p: 0 }}
                      id="driver_licence"
                      error={"driver_licence" in errors}
                      name="driver_licence"
                      {...register("driver_licence")}
                      type="file"
                    />
                    <FormHelperText>
                      Accepted file types: pdf, jpg, png, jpeg, gif, Max. file
                      size: 10 MB.
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="wire_information">
                      Checking/Wire Information-Automated Clearing House
                      Information
                    </InputLabel>
                    <Select
                      labelId="wire_information"
                      id="wire_information"
                      error={
                        "wire_information" in
                        (errors.director ? errors.director : {})
                      }
                      name="director.wire_information"
                      {...register("director.wire_information")}
                      label="Checking/Wire Information-Automated Clearing House Information"
                      variant="outlined"
                    >
                      <MenuItem value={''}>
                        Select Checking/Wire Information
                      </MenuItem>
                      <MenuItem value={"1"}>Checking</MenuItem>
                      <MenuItem value={"2"}>Wire</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sx={{ textAlign: "right" }}>
                  <Divider sx={{ my: 2 }} />
                  <LoadingButton
                    loading={loading}
                    variant="contained"
                    type="submit"
                  >
                    Submit
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </React.Fragment>
        </Paper>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}
