const charities = {
  invitation_code: "",
  name: "",
  founder_name: "",
  category: "",
  mission_description: "",
  slogan: "",
  reg_number: "",
  tax_id: { 0: null },
  address: "",
  zipcode: "",
  phone: "",
  charity_email: "",
  web_url: "",
  charity_promotional_video: "",
  charity_facebook: "",
  charity_instagram: "",
  charity_twitter: "",
  approved_logo_url: "",
  applicant_name: "",
  image: "",
  image_f: { 0: null },
  corporate_resolution: { 0: null },
  driver_licence: { 0: null },
  director: {
    name: "",
    postal_address: "",
    phone: "",
    email: "",
    wire_information: '',
  },
};

export default charities;
